.responsiveFlex {
  display: flex; /* Enable flex layout */
  flex-direction: row; /* Default direction */
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

/* Media query for screens smaller than 600px */
@media (max-width: 600px) {
  .responsiveFlex {
    flex-direction: column; /* Stack items vertically on small screens */
  }
}

.responsiveFlexContainer {
  display: flex;
  flex-direction: row; /* Explicitly set the default direction */
  gap: 5px; /* Adjust gap as needed */
}

@media (max-width: 600px) {
  .responsiveFlexContainer {
    flex-direction: column;
  }
}

/* styles.module.css */
:global {
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
}

.pulsate {
  animation: pulsate 1s infinite;
  padding-right: 5px;
  cursor: pointer;
  font-size: large;
}
