/* GENERAL */
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.LogoTop {
  font-size: 20px;
  margin: 0px 0px 0px 16px;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  letter-spacing: 4px;
}

.logoNavBar {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

#homeunderlay.page-underlay {
  margin-top: 135px !important;
}

.about-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 5px;
  align-items: start;
  width: 85%;
}

.inner-whatismatpass-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.website-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
}

.carousel-container {
  width: 100%;
}

.headertext {
  font-family: "Raleway";
  font-style: normal;
  font-size: 36px;
}

.ptext {
  font-family: "Raleway";
  font-style: normal;
  font-size: 16px;
  line-height: 28px !important;
}

.smallLineHeight {
  line-height: 23px !important;
}

.mediumLineHeight {
  line-height: 25px !important;
}

.display-linebreak {
  white-space: pre-line;
  vertical-align: bottom;
}

.section-container,
.page,
.page-underlay {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.section-container {
  padding-top: 80px;
  margin-top: -80px;
}

.section-container {
  /* VISUAL GUIDE */
  /* border: 3px solid darkgray; */
  /*  */
  width: 100%;
}

.page {
  /* VISUAL GUIDE */
  /* border: 3px solid rgb(255, 0, 0); */
  /*  */
  width: 100%;
  /* min-height: calc(100vh - 95px); */
}

.page-underlay {
  width: 95%;
  max-width: 1170px;
  margin: 80px 0px 80px 0px;
}

.page-underlay-noTop {
  width: 95%;
  max-width: 1170px;
  margin: 0px 0px 80px 0px;
}

.about-us-underlay {
  align-content: start;
  justify-content: start;
}

.page-underlay-fullWidthBanner {
  /* VISUAL GUIDE */
  background: linear-gradient(88.62deg, rgba(154, 58, 171, 0.5) 3.82%, rgba(6, 156, 189, 0.5) 95.7%);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
  /*  */
  width: 106%;
  min-height: auto;
}

/* HOME */
.grid-Text-Image {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 5px;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

/* ABOUT */

.whatismatpass {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.whatismatpass-text {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;

  color: #ffffff;
}

.whatismatpass-icon {
  border: 1px solid white;
  margin-right: 7px;
  padding: 3px;
  width: 50px;
  height: 10px;
}

.gradient-background-page {
  background: linear-gradient(
      76.52deg,
      rgba(255, 138, 0, 0.462) -13.75%,
      rgba(250, 146, 11, 0.228) 45.06%,
      rgba(244, 157, 27, 0) 132.9%
    ),
    linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
  color: white;
}

.gradient-background-page-Image {
  background: url(https://circuland-website-images.s3.eu-west-2.amazonaws.com/2.0.webp),
    linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.gradient-background-page-HowItWorks {
  background: url(https://circuland-website-images.s3.eu-west-2.amazonaws.com/10.0.webp),
    linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.gradient-background-page-NewPassMat2 {
  background: url(https://circuland-website-images.s3.eu-west-2.amazonaws.com/12.0.webp),
    linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.gradient-background-page-NewPassMat {
  background: url(https://circuland-website-images.s3.eu-west-2.amazonaws.com/14.0.webp),
    linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.lifecycleMatPasTitle {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;

  color: #1f5370;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.lifecycleMatPasSubtitle {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 172.52%;
  /* or 35px */

  color: #905e98;

  margin: 30px 0px 20px 0px;

  white-space: pre-line;
  vertical-align: bottom;
}

.lifecyclematpassListItem {
  font-family: "Raleway";
  font-style: normal;
  font-size: 16px;
  line-height: 172.52%;
  /* or 28px */

  color: #1f5370;

  margin-bottom: 20px;
}

.grid-Text-Image-LARGEIMAGE {
  width: 95%;
  display: grid;
  grid-template-columns: 35% 65%;
  grid-gap: 5px;
  justify-items: center;
  align-items: start;
  justify-content: center;
  align-content: center;
}

.lifecycleMatPasheaderContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  width: 95%;
}

.lifecyclematpassImageContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-end;
}

/* CICRULAND PLATFORM */

.lcmp-image {
  background: none !important;
  box-shadow: none !important;
  width: 90% !important;
  margin: 40px 0px 0px 0px !important;
}

.circuland-platform-gradient-page {
  /* background: linear-gradient(100.26deg, #022A41 -13.92%, #1F5370 118.92%); */
  background: url(https://circuland-website-images.s3.eu-west-2.amazonaws.com/4.0.webp),
    linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.gradient-background-page-Image-theFacts {
  /* background: linear-gradient(100.26deg, #022A41 -13.92%, #1F5370 118.92%); */
  background: url(https://circuland-website-images.s3.eu-west-2.amazonaws.com/6.0.webp),
    linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.gradient-background-page-Image-synergies {
  /* background: linear-gradient(100.26deg, #022A41 -13.92%, #1F5370 118.92%); */
  background: url(https://circuland-website-images.s3.eu-west-2.amazonaws.com/8.0.webp),
    linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.circuland-platform-top-section-header-container {
  max-width: 65%;
  padding: 0px 0px 20px 50px;
}

.grid-topBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.BottomSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.smallCardHolder-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  row-gap: 50px;
  justify-items: center;
}

.smallCard-icon-text-100pc-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.smallCard-icon-text {
  width: 240px;
  min-height: 340px;
  display: grid;
  grid-template-areas:
    "icon"
    "title"
    "text";
  background: #ffffff;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.61);
  border-radius: 35px;
  opacity: 0.8;
}

.icon,
.title,
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.circleHeaderDiv {
  width: 100px;
  border-radius: 100%;
  display: flex;
  height: 100px;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: -70px;
  margin-left: 31%;
  background: #d9d9d9;
  box-shadow: 4px 7px 4px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}

.circleHeaderDivInner {
  width: 90%;
  border-radius: 100%;
  display: flex;
  height: 90%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(162.21deg, #a27750 7.43%, rgb(186 154 124 / 47%) 112.89%); */
  border: 4px solid #ffffff;
  box-shadow: 4px 7px 4px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}

.infographic-listItem {
  font-family: "Raleway";
  font-style: normal;
  font-size: 16px;
  line-height: 172.52%;
  /* or 28px */

  color: #000000;

  margin-bottom: 20px;
}

ol {
  border: 1px solid #f00;
  height: 100%;
}

.card-icon-image {
  padding-top: 15px;
  width: 50%;
}

.smallCard-title-header {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  text-align: center;
  color: #905e98;
  margin: 0;
  line-height: 22px;
}

.smallCard-title-header-SynergiesCustom {
  border-radius: 15px;
  padding: 20px;
  width: 90%;
  background-color: #61adb7;
  color: white;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  text-align: center;
  margin: 0;
  line-height: 22px;
}

.smallCard-text-description {
  font-family: "Raleway";
  font-style: normal;
  text-align: center;
  color: #000000;
  font-size: 14px;
  line-height: 22px;
  padding: 0px 15px 15px 15px;
}

.circulandplatformButton {
  margin: 26px 0px 40px 0px;
}

.infographic-container {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 5px;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.circuland-platform-gradientBackground-2 {
  background: linear-gradient(92.72deg, #07324b 2.03%, #027585 128.17%);
  color: white;
}

.CirculandPlatformBenefits-grid {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 50px;
  justify-items: center;
  margin-top: 20px;
}

.benefitCard {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 200px;
  /* border: 1px solid #CFCFCF; */
  /* background: #ECECEC; */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 20px;
  padding: 10px;
  min-height: 200px;
}

.benefitCardIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefitCardIcon {
  width: 60%;
}

.benefitCardDescription {
  font-family: "Raleway";
  font-style: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  min-height: 100px;
  text-align: center;
}

.speechmark {
  font-size: 50px;
  visibility: hidden;
}

.imageSynergies {
  background-image: url("https://circuland-website-images.s3.eu-west-2.amazonaws.com/8.webp"),
    linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: stretch;
}

/* ACTORS */
.actorsCardIcon {
  width: 60%;
  margin-right: 10px;
}

.actorsCardTitle {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 10px 0px 10px 0px;
  color: rgb(102, 102, 102);
  text-align: center;
  min-height: 10px;
  display: flex;
  align-content: center;
  align-items: center;
}

.actorsCardDescription {
  font-family: "Raleway";
  font-style: normal;
  font-size: 13px;
  display: flex;
  align-items: center;
  min-height: 30px;
  text-align: center;
  padding: 0 0 20px 0;
}

.CirculandPlatformActors-grid {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 20px;
  justify-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.actorCardContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  max-width: 250px;
  min-height: 330px;
  border: 1px solid rgb(203, 203, 203);
  border-radius: 5px;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.187);
  padding: 20px 10px 0px 10px;
}

.actorCard {
  text-align: center;
  color: #6d6d6d;
  border: 0.5px solid #bcbcbc;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background: #7ec0c1;
  border-radius: 23px;
  height: 200px;
  margin-top: -35px;
}

.actorCardIcon {
  z-index: 2;
  background-color: white;
  /* background: linear-gradient(180deg, #37B5C8 0%, #D2EAEE 100%);
  border: 0.5px solid #68AFBE; */
  border-radius: 100%;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.actorCardTitle {
  padding-top: 30px;
}

/* HOW IT WORKS */
.howitworks-dual-grid {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 50px;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.left-grid,
.right-grid {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.left-grid {
  align-items: start;
}

/* CreationofMaterialsPassports */
.rowFlexContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.rowGridContainer {
  display: grid;
  grid-template-columns: 120px auto;
  /* grid column gap */
  grid-gap: 40px;
  margin-bottom: 30px;
}

.creationOfMatPassDescription {
  font-family: "Raleway";
  font-style: normal;
  font-size: 15px;
}

.rowIconContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #87aaab !important;
  border-radius: 100%;
  height: 90px;
  width: 90px;
}

.rowIconContainer-left,
.rowIconContainer-right {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: start;
}

.rowIconContainer-left {
  align-items: center;
}

.howitworks-dual-grid-newProducts {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 60px;
  justify-items: start;
  align-items: start;
  justify-content: start;
  align-content: start;
  margin-bottom: 60px;
}

.left-grid-newProducts,
.right-grid-newProducts {
  display: flex;
  flex-direction: column;
  align-content: start;
  justify-content: start;
  align-items: start;
}

.matdatabase-section2 {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.matdatabase-section4-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 50px;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.contactpage {
  /* background: linear-gradient(75deg, #42275a, #004e92, #5a6874); */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: stretch;
  color: black;
}

/* TYPOGRAPHY */
.welcome-h1 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.06em;

  background: linear-gradient(89.9deg, #bc249f -11.73%, #299edd 146.51%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0px;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.subheading-h2 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.09em;

  background: linear-gradient(89.9deg, #bc249f -11.73%, #299edd 146.51%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.paragraph-text {
  width: 100%;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;

  color: #000000;
}

.home-circuland-button {
  width: 292px;
  height: 52px;
  left: 94px;
  top: 711px;

  background: #a238a9;

  border-radius: 44px;
  color: white;
  border: none;
  margin: 0px !important;
}

.matstockdbheadecontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: nowrap;
}

.home-circuland-button:hover {
  width: 292px;
  height: 52px;
  left: 94px;
  top: 711px;

  /* background: #9b829d; */
  background: #ffc336;
  border-radius: 44px;
  /* color: white; */
  color: black;

  font-weight: bold;

  border: none;
}

.creationOfMatPassHeaderContainer {
  display: flex;
  width: 95%;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 10px;
}

.howitworksheaders {
  color: rgba(255, 153, 0, 1);
  font-size: 24px;
  margin-bottom: 25px;
}

.circulandMarketplace-section2 {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

/* SECTION - HOME */

.section-container-HOME {
  margin-top: 0px;
}

.page-underlay-HOME {
  margin-bottom: 0px;
}

/* ENDOFSECTION - HOME */

/* SECTION - ABOUT */
/* ENDOFSECTION - ABOUT */

/* SECTION - CIRCULANDPLATFORM */
/* ENDOFSECTION - CIRCULANDPLATFORM */

/* SECTION - HOWITWORKS */

.DEFAULT-PAGE-HOW_IT_WORKS_PAGE {
  background-color: #072e2e;
  color: white;
}

.DEFAULT-UNDERLAY-HOW_IT_WORKS_PAGE {
  margin: 70px 0px 80px 0px !important;
}

.DEFAULT-UNDERLAY-CREATION-OF-MAT-PASS {
  margin: 70px 0px 50px 0px;
}

.DEFAULT-PAGE-MAT-PASS-FOR-NEW-PRODS {
  background-color: #072e2e;
  color: white;
}

.DEFAULT-WIDTH-70PC {
  width: 70%;
}

.DEFAULT-MARGIN-TOP-55PX {
  margin-top: 55px;
}

.DEFAULT-MARGIN-TOP-70PX {
  margin-top: 70px;
}

.DEFAULT-MARGIN-BOTTOM-70PX {
  margin-bottom: 70px;
}

.DEFAULT-MARGIN-BOTTOM-20PX {
  margin-bottom: 20px;
}

.DEFAULT-MARGIN-BOTTOM-100PX {
  margin-bottom: 100px;
}

.DEFAULT-MARGIN-TOP-100PX {
  margin-top: 100px;
}

.DEFAULT-BACKGROUND-LIGHTGRAY {
  background-color: lightgray;
}

.consentTextContainerFlex {
  font-family: "Raleway";
  font-style: normal;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;
}

.cookiesImage {
  width: 35%;
}

.consentImageContainerFlex {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.savePreferences {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.consentButtonContainerFlex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.consentNoButton {
  margin-right: 10px;
}

.changeConsentLinkContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.changeConsentLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #608dfd;
  cursor: pointer;
}

.contactUsControl {
  border-radius: 20px !important;
}

/* ENDOFSECTION - HOWITWORKS */

/* SECTION - CONTACT */
/* ENDOFSECTION - CONTACT */
.image14 {
  width: 70%;
}

/* MOBILE VIEW */
@media screen and (max-width: 900px) {
  .image14 {
    width: 100%;
  }

  .cookiesImage {
    width: 35%;
  }

  .lcmp-image {
    width: 100% !important;
  }

  #homeunderlay.page-underlay {
    margin-top: 135px !important;
  }

  .page-underlay {
    /* VISUAL GUIDE */
    /* border: 3px solid rgb(68, 0, 255); */
    /*  */
    width: 95%;
    max-width: 1170px;
    /* min-height: calc(100vh - 200px); */
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 85px 0px 85px 0px !important;
  }

  #MaterialsPassportsSynergies.headertext {
    margin-bottom: 30px;
  }

  .headertext {
    font-size: 27px;
  }

  .SynergiesMobileView {
    margin: 70px 0px 70px 0px !important;
    padding: 0 !important;
  }

  .smallCardHolder-grid {
    /* visibility: hidden; */
  }

  .mobileview-headertext {
    width: 90%;
    text-align: center;
  }

  .CirculandPlatformBenefits-grid {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    row-gap: 0px;
    column-gap: 36px;
    justify-items: center;
    margin-top: 0px;
  }

  .benefitCardDescription {
    font-family: "Raleway";
    font-style: normal;
    font-size: 14px;
    display: flex;
    align-items: center;
    min-height: 100px;
    text-align: center;
  }

  .benefitCard {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 175px;
    /* border: 1px solid #CFCFCF; */
    /* background: #ECECEC; */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 20px;
    padding: 10px;
    min-height: 175px;
  }

  .grid-Text-Image-LARGEIMAGE {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 0 100px 0;
  }

  /* ABOUT */
  .grid-Text-Image-LARGEIMAGE {
    padding: 0;
  }

  .page-underlay-LifeCycleMaterialPassport {
    /* padding: 70px 0px 0px 0px;
    margin: 0px; */
  }

  /* CIRCULAND PLATFORM */
  .circuland-platform-gradient-page {
    background: none;
    background-image: linear-gradient(100.26deg, #022a41 -13.92%, #1f5370 118.92%);
    background: url(https://circuland-website-images.s3.eu-west-2.amazonaws.com/4.0.mob.webp),
      linear-gradient(89.9deg, #08262d -11.73%, #092934 146.51%);
    background-position: top;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    color: white;
  }

  .circuland-platform-top-section-header-container {
    max-width: 95%;
    padding: 0px 0px 20px 15px;
  }

  .mobile-rowGridContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .rowIconContainer-right {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .howitworks-dual-grid-newProducts {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 60px;
    justify-items: start;
    align-items: start;
    justify-content: start;
    align-content: start;
    margin-bottom: 30px;
  }

  .lifecycleMatPasSubtitle {
    white-space: initial;
    vertical-align: none;
    margin-top: 20px;
    font-size: 13pt;
  }

  .about-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-gap: 5px;
    align-items: start;
    width: 90%;
  }

  .mobile-center-view {
    width: 100%;
    display: flex;
    justify-content: center;
    direction: column;
  }
}

/* NOTE - Careers */

/* Job Pane */
.grid-Text-careers {
  width: 95%;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-areas: "left right";
  grid-gap: 5px;
}

.grid-Text-careers > div:first-child {
  grid-area: left;
  padding-left: 16px;
}

.grid-Text-careers > div:last-child {
  grid-area: right;
  padding-left: 16px;
}

@media (max-width: 800px) {
  .grid-Text-careers > div:first-child {
    grid-area: left;
    padding-left: 0px;
    justify-content: center;
  }

  .grid-Text-careers {
    grid-template-columns: 1fr;
    grid-template-areas:
      "left"
      "right";
  }
}
/* Job Buttons */
.post-container {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 16px;
  margin: 16px 0;
  background-color: #fff;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.post-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.post-header {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 48px !important;
  height: 48px !important;
  border-radius: 50%;
  margin-right: 16px;
}

.post-info h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.post-info p {
  margin-top: 10px;
  color: #666;
  line-height: normal;
}

.post-body {
  color: #333;
}

/* Bullet Points */
.dotted-list {
  list-style-type: disc;
  padding-left: 20px; /* Adjust the value as needed for indentation */
}

/* Mobile roles dropdown */

.mobile-dropdown {
  display: block;
  width: 90%; /* Set width to 90% of its container */
  padding: 8px;
  border: 1px solid #ccc; /* Add a light border */
  border-radius: 5px; /* Optional: rounds the corners */
  background-color: white; /* Set background color */
}

@media (max-width: 800px) {
  .post-container {
    display: none;
  }

  .mobile-dropdown {
    display: block;
  }
}
